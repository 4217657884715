<script lang="ts">
  import { isLoggedIn$ } from './model/user';
  import Landing from './pages/Landing.svelte';
  import Login from './pages/Login.svelte';
  import Signup from './pages/Signup.svelte';
  import { Router, link, links, Route } from 'svelte-routing';
  import Tailwind from './components/Tailwind.svelte';

  export let url = '';
</script>

<svelte:head>
  <title>Oulipoetry</title>
</svelte:head>

<Tailwind />

<Router {url}>
  <main class="h-screen outline-none font-mono overflow-hidden">
    <Route path="login" component={Login} />
    <Route path="signup" component={Signup} />
    <Route path="" component={Landing} />
  </main>
</Router>
