<script lang="ts">
  import InboxMessageButton from './InboxMessageButton.svelte';
  export let user: string;
  export let message: string;
</script>

<InboxMessageButton>
  <p>
    <span class="font-extrabold text-xl">{user}</span>
    <br />
    <span class="text-lg">{message}</span>
  </p>
</InboxMessageButton>
