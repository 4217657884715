// RxJS v6+
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { SvelteSubject } from '../common/svelte-subject';
// Emit a date every second
const ticker$ = interval(1000).pipe(map(_ => new Date()));
export const now$ = new SvelteSubject(new Date());
ticker$.subscribe(now$);
export function makeDeadlineTimer(now, deadline) {
    let diff = (deadline.valueOf() - now.valueOf()) / 1000;
    let signum = diff / Math.abs(diff);
    diff = Math.abs(diff);
    let hours = Math.trunc(diff / 3600);
    let minutes = Math.trunc(diff / 60) % 60;
    let seconds = Math.trunc(diff % 60);
    return { hours, minutes, seconds, signum };
}
