<script lang="ts">
  import IdentityProviderButton from '../components/IdentityProviderButton.svelte';
  import { fly } from 'svelte/transition';

  import { doLogin } from '../model/user';
</script>

<!-- TODO: Redo so that this is the only thing onscreen. -->
<div
  in:fly={{ x: 200, duration: 400 }}
  out:fly={{ x: 200, duration: 400 }}
  class="absolute right-0 top-0 w-full lg:w-2/5 h-full bg-black text-white overflow-y-auto"
>
  <div class="flex flex-col items-center">
    <h1 class="text-4xl py-5">Welcome.</h1>
    <div class="w-full bg-primary text-white">
      <h2 class="font-extrabold text-xl px-5 py-5">
        To sign up or log in, continue with one of the services below:
      </h2>
    </div>
    <div>
      <ul class="text-2xl mt-10">
        <li>
          <IdentityProviderButton provider="Google" icon="google" />
        </li>
        <li>
          <IdentityProviderButton provider="Twitter" icon="twitter" />
        </li>
        <li>
          <IdentityProviderButton provider="Facebook" icon="facebook" />
        </li>
        <li>
          <IdentityProviderButton provider="Apple" icon="apple" />
        </li>
        <li>
          <IdentityProviderButton provider="Microsoft" icon="windows" />
        </li>
      </ul>
    </div>
    <h2 class="hidden lg:block text-xl px-5 py-5">
      If you already have an account and can't remember which service you used
      before, just try one. You can try again with a different service later if
      you got it wrong, and then merge your accounts so it won't matter in the
      future.
    </h2>
    <!-- <button on:click={() => doLogin('Josiah')}>Toggle</button> -->
  </div>
</div>
