<script lang="ts">
  import FriendButton from './FriendButton.svelte';
  export let users: string[];
</script>

<div class="w-full h-full flex flex-col overflow-x-hidden">
  <ul class="flex-grow w-full mx-2 overflow-y-auto">
    {#each users as user}
      <li class="my-2 px-3 border-b-2 border-black text-lg">
        <FriendButton {user} />
      </li>
    {/each}
  </ul>
  <div class="flex-none flex flex-col mx-2 my-2 px-3">
    <button
      class="h-8 mx-2 my-2 px-3 border-2 border-black text-lg"
    >Add</button>
    <input placeholder="Find friend..." class="h-16 my-2" />
    <button class="h-8 bg-black text-lg text-white">Search</button>
  </div>
</div>
