<script lang="ts">
  import { makeDeadlineTimer, now$ } from '../../model/time';
  import type { Timer } from '../../model/time';
  import InboxMessageButton from './InboxMessageButton.svelte';
  import type { Observable } from 'rxjs';
  import { map } from 'rxjs/operators';
  export let poem: string;
  export let deadline: Date;

  const timer$: Observable<Timer> = now$.pipe(
    map((now) => makeDeadlineTimer(now, deadline))
  );
</script>

<InboxMessageButton>
  <span>{poem}</span>
  <br />
  <div class="absolute bottom-0 right-0">
    <!-- prettier-ignore -->
    <span class="mr-2" class:text-error={$timer$.signum < 0}>
      {#if $timer$.signum > 0}+{:else}-{/if}{
        $timer$.hours.toString().padStart(2,'0')}:{
        $timer$.minutes.toString().padStart(2, '0')}:{
        $timer$.seconds.toString().padStart(2, '0')
      }
    </span>
  </div>
</InboxMessageButton>
