import 'rxjs';
import { SvelteSubject } from '../common/svelte-subject';
import { map, } from 'rxjs/operators';
const user$ = new SvelteSubject(undefined);
export const isLoggedIn$ = user$.pipe(map((u) => u !== undefined));
export function doLogin(username) {
    console.log("Logging in...");
    user$.next(username);
}
export function doLogout() {
    console.log("Logging out...");
    user$.next(undefined);
}
