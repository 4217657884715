<script lang="ts">
  import { isLoggedIn$ } from '../model/user';

  import { Router, link, links, Route } from 'svelte-routing';

  import Play from './Play.svelte';
  import New from './New.svelte';
  import Rules from './Rules.svelte';
  import Library from './Library.svelte';
  import Profile from './Profile.svelte';
  import SideNav from '../components/SideNav.svelte';
  import FriendPalette from '../components/FriendPalette.svelte';

  export let url = '';
</script>

<svelte:head>
  <title>Oulipoetry</title>
</svelte:head>

<Router {url}>
  <div class="w-full h-full flex flex-col overflow-hidden">
    <!-- Banner persistent across all pages -->
    <div
      class="flex flex-row justify-between items-center px-3 py-2 text-white bg-black"
    >
      <a use:link href="/" class="px-2 pb-2 text-left text-4xl">Oulipoetry</a>
    </div>

    <div class="w-full flex-grow overflow-hidden">
      <div
        class="relative flex flex-row w-full h-full py-2 px-3 text-black bg-white overflow-hidden"
      >
        <!-- Side navigation -->
        <div
          class="hidden md:block flex-none w-48 h-full mr-2 border-r-2 border-black"
        >
          <SideNav />
        </div>
        <!-- Principal playing area -->
        <div class="flex-grow h-full">
          <Route path="play" component={Play} />
          <Route path="rules" component={Rules} />
          <Route path="library" component={Library} />
          <Route path="profile" component={Profile} />
          <Route path="new" component={New} />
        </div>
        <!-- Friend list -->
        <div
          class="hidden md:flex flex-none w-64 h-full ml-2 border-l-2 mr-4 border-black flex-col items-center overflow-auto"
        >
          <FriendPalette
            users={['Molly', 'David', 'Alaina', 'Dan', 'Allison']}
          />
        </div>
      </div>
    </div>

    <!-- Status -->
    <div class="hidden md:block h-12 w-full text-white bg-black">
      <div class="px-2 pb-8 flex flex-col justify-between">
        <p>Status</p>
      </div>
    </div>
  </div>
</Router>
