<script lang="ts">
  import ChatMessageButton from './messages/ChatMessageButton.svelte';
  import PoemMessageButton from './messages/PoemMessageButton.svelte';
</script>

<ul>
  <li>
    <ChatMessageButton user="Molly" message="Hello" />
  </li>
  <li>
    <PoemMessageButton poem="Sonnet" deadline={new Date()} />
  </li>
</ul>
