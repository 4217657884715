<script lang="ts">
  async function handleSubmit(event) {
    console.log(event);
    console.log(event.target);
    console.log(event.target.email.value);
    console.log(event.target.password.value);
  }

  // TODO: On username edit, debounce but check to see whether taken
  // https://www.nielsvandermolen.com/signup-form-html5-validation-svelte/
</script>

<div
  class="absolute right-0 top-0 w-full lg:w-2/5 h-full bg-black text-white overflow-y-auto"
>
  <div class="flex flex-col items-center">
    <h1 class="text-4xl py-5">Sign up.</h1>
    <div class="w-full bg-primary text-white">
      <form on:submit|preventDefault={handleSubmit}>
        <label for="username">Username</label>
        <input required type="username" id="username" />

        <label for="given name">Given Name</label>
        <input required id="given_name" />

        <label for="family name">Family Name</label>
        <input required id="family_name" />

        <label for="email">Email</label>
        <input required type="email" id="email" />

        <button type="submit">Create Account</button>
      </form>
    </div>
    <div />
  </div>
</div>
