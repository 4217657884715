<script lang="ts">
  export let icon: string;
  export let provider: string;
</script>

<div
  class="h-12 w-64 my-3 border-2 border-white flex flex-row items-center no-select cursor-pointer"
>
  <div class="w-1/3 text-center">
    <i class={'fab fa-' + icon} aria-hidden="true" />
  </div>
  <a href={API_URL + '/auth/' + provider.toLowerCase()}>{provider}</a>
</div>
